import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { RuntimeState, RuntimeEnv } from '@wearejh/m2-pwa-engine/lib/runtime';
import { Observable } from 'rxjs';
import { RouterState } from '@wearejh/m2-pwa-engine/lib/router/router.register';
import { UserState } from '@wearejh/m2-pwa-user';
import { CartState } from '@wearejh/m2-pwa-cart-gql';
import { ApolloClient } from 'apollo-client';

import { paths, text, contact, socialLinks } from '../config.json';
import { AppState } from '../features/app/app.reducer';

/**
 * AppEnv is a combination of app-specific vars +
 * the required vars from the pwa runtime
 */
export type AppEnv = RuntimeEnv & {
    ALGOLIA_APP_ID: string;
    ALGOLIA_API_KEY: string;
    ALGOLIA_INDEX_PREFIX: string;
    GTM_ID: string;
    GTM_ENABLED: boolean;
    BACKEND: string;
    SSR_GQL: boolean;
    GOOGLE_MAPS_API_KEY: string;
    KLARNA_ENABLED: boolean;
    ONETRUST_API_KEY: string;
    YOTPO_API_KEY: string;
    YOTPO_ENABLED: boolean;
    NOSTO_ENABLED: boolean;
    NOSTO_ID: string;
    WP_BACKEND: string;
    ORIGIN_UL: string;
    ORIGIN_UL_MAGENTO: string;
    ORIGIN_UL_CLIENT: string;
    ORIGIN_UL_MAGENTO_CLIENT: string;
    ALGOLIA_INDEX_PREFIX_WP: string;
    WP_USER: string;
    WP_APP_KEY: string;
    LOQATE_API_KEY: string;
};

/**
 * AppDeps is the single global place to store things
 * you may want access in many places.
 *
 * Use this to add things to make them available in epics & components
 */
export interface AppDeps {
    paths: typeof paths;
    text: typeof text;
    contact: typeof contact;
    socialLinks: typeof socialLinks;
    pbExtractHtml: (str: string) => string;
}

/**
 * This simply gives access to GQL queries made after SSR
 */
export type QueryMirror = { operationName: string; data: any };

/**
 * Use this type everywhere in the Application as it will give you
 * a combined object with everything visible on the runtime deps + your own
 */
export type Deps = EpicDeps<AppEnv, ApolloClient<any>> & AppDeps;

export interface StoreState {
    router: RouterState;
    runtime: RuntimeState;
    user: UserState;
    cart: CartState;
    app: AppState;
}

export enum ProductType {
    BundleProduct = 'BundleProduct',
    ConfigurableProduct = 'ConfigurableProduct',
    SimpleProduct = 'SimpleProduct',
    DownloadableProduct = 'DownloadableProduct',
    GroupedProduct = 'GroupedProduct',
    VirtualProduct = 'VirtualProduct',
}

export enum StockStatus {
    InStock = 'in',
    OutOfStock = 'out',
}

export type MediaEntry = {
    large_height: number;
    large_width: number;
    large_url: string;
    medium_url: string;
    small_url: string;
    media_type: string;
    position: number;
    label: string;
    hidden: boolean;
    video_content?: {
        media_type?: string;
        video_provider?: string;
        video_url?: string;
        video_title?: string;
        video_description?: string;
        video_metadata?: string;
    };
};

export type ProductPrice = {
    price_incl: number;
    price: number;
    price_per?: string | null;
    discount?: number | string | null;
    regular_price?: number | string | null;
};

export function productPriceFromInt(int: number): ProductPrice {
    return { price_incl: int, price: int };
}

export const DEFAULT_PRICE: ProductPrice = {
    price_incl: 0,
    price: 0,
};

// https://date-fns.org/v2.4.1/docs/format
export enum DateFormat {
    Order = 'd/MM/yy',
    OrderPage = 'd MMMM yyyy',
    Blog = 'MMMM do, y',
    Time = 'h:m aaa',
}

export type EpicReturn = Observable<{ type: string; payload?: any }>;
export const noop = () => {
    /* noop */
};
export const todo =
    (msg = 'todo') =>
    (...args: any[]) => {
        console.warn('todo: %s', msg, args);
        alert(msg);
    };
